import React from 'react';

type ColProps = {
  align?: string;
  children?: React.ReactNode;
  className?: string;
  colOffset?: number;
  fixedWidth?: string;
  lg?: number;
  lgOffset?: number;
  md?: number;
  mdOffset?: number;
  noGutter?: boolean;
  offset?: number;
  size?: number;
  sm?: number;
  smOffset?: number;
  style?: Record<string, unknown>;
  xl?: number;
  xlOffset?: number;
};

type GridProps = {
  align?: string;
  children?: React.ReactNode;
  className?: string;
  direction?: string;
  justify?: string;
  nested?: boolean;
  noGutter?: boolean;
  style?: Record<string, unknown>;
};

const getSizeClass = (size: number | undefined): string => (size === 1 || size === undefined ? '' : `-${size}`);

export const Col = (props: ColProps): JSX.Element => {
  const {
    align,
    children,
    className,
    colOffset,
    fixedWidth,
    lg,
    lgOffset,
    md,
    mdOffset,
    noGutter,
    offset,
    size,
    sm,
    smOffset,
    style,
    xl,
    xlOffset,
  } = props;
  const alignClass = ` p-col-align-${align}`;
  const colOffsetClass = colOffset ? ` p-col-offset-${colOffset}` : '';
  const fixed = fixedWidth !== '';
  const fixedClass = fixed ? '-fixed' : '';
  const lgClass = lg ? ` p-lg-${lg}` : '';
  const lgOffsetClass = lgOffset ? ` p-lg-offset-${lgOffset}` : '';
  const mdClass = md ? ` p-md-${md}` : '';
  const mdOffsetClass = mdOffset ? ` p-md-offset-${mdOffset}` : '';
  const noGutterClass = noGutter ? ' p-col-nogutter' : '';
  const offsetClass = offset ? ` p-offset-${offset}` : '';
  const smClass = sm ? ` p-sm-${sm}` : '';
  const smOffsetClass = smOffset ? ` p-sm-offset-${smOffset}` : '';
  const xlClass = xl ? ` p-xl-${xl}` : '';
  const xlOffsetClass = xlOffset ? ` p-xl-offset-${xlOffset}` : '';
  return (
    <div
      className={`p-col${fixedClass}${getSizeClass(
        size,
      )}${alignClass}${colOffsetClass}${noGutterClass}${offsetClass}${smClass}${smOffsetClass}${mdClass}${mdOffsetClass}${lgClass}${lgOffsetClass}${xlClass}${xlOffsetClass} ${className}`}
      style={{ width: fixedWidth, ...style }}
    >
      {children}
    </div>
  );
};

Col.defaultProps = {
  align: 'stretch',
  children: null,
  className: '',
  colOffset: null,
  fixedWidth: '',
  lg: null,
  lgOffset: null,
  md: null,
  mdOffset: null,
  noGutter: false,
  offset: null,
  size: 1,
  sm: null,
  smOffset: null,
  style: null,
  xl: null,
  xlOffset: null,
};

export const Grid = (props: GridProps): JSX.Element => {
  const {
    align,
    children,
    className,
    direction,
    justify,
    nested,
    noGutter,
    style,
  } = props;
  const alignClass = ` p-align-${align}`;
  const directionClassName = direction ? ` p-dir-${direction}` : '';
  const justifyClass = ` p-justify-${justify}`;
  const nestedClassName = nested ? ' nested-grid' : '';
  const noGutterClass = noGutter ? ' p-nogutter' : '';
  return (
    <div
      className={`p-grid${alignClass}${nestedClassName}${directionClassName}${justifyClass}${noGutterClass} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};

Grid.defaultProps = {
  align: 'stretch',
  children: null,
  className: '',
  direction: null,
  justify: 'start',
  nested: false,
  noGutter: false,
  style: null,
};

export default {
  Col,
  Grid,
};
