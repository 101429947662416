import React from 'react';
import ReactDOM from 'react-dom';
import PrimeReact from 'primereact/api';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import GlobalStyle from './GlobalStyle';
import reportWebVitals from './reportWebVitals';

// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
import 'primereact/resources/themes/saga-purple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fontsource/fira-sans';

PrimeReact.ripple = true;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
