import React from 'react';

import { Switch, Route } from 'react-router-dom';

import { Card } from 'primereact/card';

import { Col, Grid } from './Flex';
import Home from './Home';

const App = (): JSX.Element => (
  <Grid align="center" justify="center" style={{ height: '100%' }}>
    <Col
      align="center"
      size={10}
      style={{
        textAlign: 'center',
      }}
      sm={10}
      md={8}
      lg={6}
      xl={5}
    >
      <Card className="p-shadow-18" style={{ borderRadius: '10px' }}>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Card>
    </Col>
  </Grid>
);

export default App;
